import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img1 from "./../assets/Images/HowItWork1.jpg";
import img2 from "./../assets/Images/HowItWork2.jpg";
import img3 from "./../assets/Images/HowItWork3.jpg";
import one from "./../assets/one.svg";
import two from "./../assets/two.svg";
import three from "./../assets/three.svg";
import strings from "../strings";

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
  const [isMediumOrLarger, setIsMediumOrLarger] = useState(
    window.innerWidth >= 780
  );
  const svgRef = useRef(null);
  const feature1Ref = useRef(null);
  const feature2Ref = useRef(null);
  const feature3Ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMediumOrLarger(window.innerWidth >= 780);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMediumOrLarger && svgRef.current) {
      const svgPath = svgRef.current.querySelector("path");
      const pathLength = svgPath.getTotalLength();

      // Custom dash pattern
      const dashArray = "17 7 20 10"; // Small dash, gap, big dash, gap
      svgPath.style.strokeDasharray = dashArray;

      gsap.fromTo(
        svgPath,
        { strokeDashoffset: pathLength },
        {
          strokeDashoffset: 0,
          scrollTrigger: {
            trigger: svgRef.current,
            start: "top center",
            end: "bottom center",
            scrub: 1, // Adjust the speed
          },
        }
      );
    }

    [feature1Ref, feature2Ref, feature3Ref].forEach((ref) => {
      gsap.fromTo(
        ref.current,
        { opacity: 1, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "sine",
          scrollTrigger: {
            trigger: ref.current,
            start: "top 60%",
            end: "top 30%",
          },
        }
      );
    });
  }, [isMediumOrLarger]);

  return (
    <>

      <section
        id="howitwork"

      >
        <div className="relative text-center md:text-left sm:px-8 md:px-16 lg:px-20 xl:px-40 mt-32 z-10">
          {/* Title  */}
          <h2 className="font-serif text-center uppercase text-[1.5rem] sm:text-[1.6rem] md:text-[2rem] lg:text-[2.5rem] xl:text-[3rem] 2xl:text-[3.5rem] lg:ms-5 font-semibold text-black ">
            {strings.howIt}
            <span className="text-[#1189CC]">{strings.howItWorksblue}</span>
            {strings.works}
          </h2>
          {/* <p className="mt-2 text-xs md:text-base lg:text-base xl:text-lg lg:ms-5 text-black/50 font-thin">
              {strings.howItWorksDescription}
              </p> */}
        </div>
        <div className=" h-[100%] overflow-y-hidden w-full grid grid-cols-1 md:grid-cols-3 p-0 mx-auto py-4 lg:py-20 gap-8 relative">
          {/* SVG PATH */}
          {isMediumOrLarger ? (
            <div className="absolute inset-0 flex items-center justify-center h-full -z-10 overflow-hidden ">
              <svg
                ref={svgRef}
                width="100%"
                height="auto"
                viewBox="0 0 1440 422"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-146 280.999C65.5 512.999 102.087 407.014 324.5 313.5C456.5 258 522 -237 724.5 143C740.834 173.65 907.06 100.927 936 244C1016 639.5 1320.5 160.5 1470 267.5"
                  stroke="#084466"
                  strokeDasharray="17 7 20 10"
                  strokeOpacity="1"
                />
              </svg>
            </div>
          ) : (
            <div className="absolute inset-0 -top-[4rem] xs:-top-[5rem] flex items-center justify-center h-[100%] -z-10 overflow-hidden ">
              <svg
                ref={svgRef}
                width="auto"
                height="62%"
                viewBox="0 0 176 377"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M77.1379 1C57.8046 2.66667 17.0379 17.2 8.63788 62C6.97121 86.1667 32.0938 152.567 95.5 137.5C146 125.5 190.5 202 170.138 244.5C182.971 255.667 166 326.5 114 329C107.507 329.312 105 321.5 112.5 318.5C125.8 313.18 127.737 345.922 97 350.5C73.5 354 5.4 324 1 376"
                  stroke="#084466"
                  strokeWidth="0.5"
                  strokeDasharray="4 4"
                  strokeOpacity="1"
                />
              </svg>
            </div>
          )}
          {/* Feature 1  */}
          {isMediumOrLarger ? (
            <div
              ref={feature1Ref}
              className="flex flex-col items-center justify-center p-10 xl:p-0 rounded-lg max-w-xl mx-auto"
            >
              <h3 className="font-sans text-center text-[1.5rem] lg:text-[2.2rem] font-semibold">
                {strings.work1}
              </h3>
              <p className="text-[0.8rem] lg:text-[1.1rem] text-gray-600 text-center font-light 2xl:w-2/3  ">
                {/* {strings.feature1Description.map((desc, index) => ( */}
                <p >{strings.work1Description}</p>
                {/* ))} */}
              </p>
              <div className="flex flex-col items-center justify-center ">
                <div className="z-30">
                  <img src={one} className="md:scale-75 lg:scale-100" alt="1" />
                </div>
                <div className="z-10 -mt-16">
                  <img
                    src={img1}
                    className="2xl:w-96 max-w-full scale-[95%] md:scale-100"
                    alt="Feature 1"
                  />
                </div>
              </div>
            </div>
          ) : (
            // Feature 1 - for small screens
            <div
              ref={feature1Ref}
              className=" flex  gap-x-3 items-start justify-start   px-6  rounded-lg h-[80%] mt-5 "
            >
              <div className="z-10 flex-col w-1/2  h-[100%] content-center ">
                <img src={img1} className="  " alt="Feature 1" />
              </div>

              <div className=" content-center  h-[100%] flex-col w-3/5  ">
                <img src={one} className="  w-[25%] p-0 -m-2 " alt="1" />

                <h3 className="font-sans  text-[0.95rem]  font-semibold pr-5 mb-1">
                  {strings.work1}
                </h3>
                <p className="text-[0.75rem]  text-gray-600 font-light">
                  {/* {strings.feature1Description.map((desc, index) => ( */}
                  <p >{strings.work1Description}</p>
                  {/* ))} */}
                </p>
              </div>
            </div>
          )}
          {/* Feature 2  */}
          {isMediumOrLarger ? (
            <div
              ref={feature2Ref}
              className="flex flex-col items-center justify-center p-10 rounded-lg max-w-xl mx-auto"
            >
              <div className="flex flex-col items-center justify-center mt-30">
                <div className="z-10">
                  <img
                    src={img2}
                    className="2xl:w-96 max-w-full scale-[95%] md:scale-100"
                    alt="Feature 2"
                  />
                </div>
                <div className="z-30 -mt-20">
                  <img
                    src={two}
                    className="scale-100 md:scale-75 lg:scale-100"
                    alt="2"
                  />
                </div>
              </div>
              <h3 className="font-sans font-semibold text-center text-[2rem] md:text-[1.5rem] lg:text-[2.25rem]">
                {strings.work2}
              </h3>
              <p className="text-[1.0rem] md:text-[0.8rem] lg:text-[1.1rem] text-gray-600 text-center font-light 2xl:w-2/3">
                {/* {strings.feature2Description.map((desc, index) => ( */}
                <p >{strings.work2Description}</p>
                {/* ))} */}
              </p>
            </div>
          ) : (
            <div
              ref={feature1Ref}
              className=" flex  gap-x-3 items-start justify-start px-6  rounded-lg h-[80%] -mt-2  -mb-3"
            >
              <div className="content-center  h-[100%] flex-col w-3/5  ">
                <img
                  src={two}
                  className="w-[34%] -m-2 -ml-4 overflow-hidden justify-self-start items-start content-start  "
                  alt="2"
                />

                <h3 className="font-sans  text-[0.95rem]  font-semibold  mb-1 text-pretty ">
                  {strings.work2}
                </h3>
                <p className="text-[0.75rem]  text-gray-600 font-light ">
                  {/* {strings.feature2Description.map((desc, index) => ( */}
                  <p >{strings.work2Description}</p>
                  {/* ))} */}
                </p>
              </div>
              <div className="z-10 flex-col w-1/2  h-[100%] content-center  ">
                <img src={img2} className="  " alt="Feature 2" />
              </div>
            </div>
          )}
          {/* Feature 3  */}
          {isMediumOrLarger ? (
            <div
              ref={feature3Ref}
              className="flex flex-col items-center justify-center p-10 xl:p-0 rounded-lg max-w-xl mx-auto"
            >
              <h3 className="font-sans text-center text-[2rem] md:text-[1.5rem] lg:text-[2.25rem] font-semibold md:mt-20">
                {strings.work3}
              </h3>
              <p className="text-[1.0rem] md:text-[0.8rem] lg:text-[1.1rem] text-gray-600 text-center font-light 2xl:w-2/3">
                {strings.work3Description}
              </p>
              <div className="flex flex-col items-center justify-center">
                <div className="z-30">
                  <img
                    src={three}
                    className="scale-100 md:scale-75 lg:scale-100"
                    alt="3"
                  />
                </div>
                <div className="z-10 -mt-16">
                  <img
                    src={img3}
                    className="2xl:w-96 max-w-full scale-[95%] md:scale-100"
                    alt="Feature 3"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              ref={feature1Ref}
              className=" flex  gap-x-3 items-start justify-start px-6  rounded-lg h-[80%] "
            >
              <div className="z-10 flex-col w-1/2  h-[100%]  content-center ">
                <img src={img3} className="" alt="Feature 3" />
              </div>

              <div className=" content-center  h-[100%] flex-col w-3/5  ">
                <img src={three} className="  w-[32%] p-0 -m-2 -ml-4" alt="3" />

                <h3 className="font-sans  text-[0.95rem] font-semibold pr-5 mb-1">
                  {strings.work3}
                </h3>
                <p className="text-[0.75rem] text-gray-600 font-light">
                  {/* {strings.feature3Description.map((desc, index) => ( */}
                  <p >{strings.work3Description}</p>
                  {/* ))} */}
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Features;
