import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import AqarHome from "../components/AqarHome"; // Imported for larger screens
import HeroMobile from "../assets/mobileHero.jpeg"; // Imported for mobile screens
import strings from "./../strings";
import { gsap } from "gsap";

const Hero = () => {
  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const descriptionRef = useRef(null);

  useLayoutEffect(() => {
    gsap.defaults({ ease: "sine" });

    const tl = gsap.timeline();
    tl.from(heroRef.current, { opacity: 0.5, scale: 2.5, duration: 1 })
      .from(titleRef.current, { opacity: 0, y: 100, scale: 3.5 }, "-=0.2")
      .from(subtitleRef.current, { opacity: 0, y: 50, scale: 2.5 }, "-=0.2")
      .from(descriptionRef.current, { opacity: 0, y: 20, scale: 1.5 }, "-=0.2")
      .to(
        [
          heroRef.current,
          titleRef.current,
          subtitleRef.current,
          descriptionRef.current,
        ],
        { opacity: 1, y: 0, scale: 1 }
      );
  }, []);

  const [isMediumOrLarger, setIsMediumOrLarger] = useState(
    window.innerWidth >= 780
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumOrLarger(window.innerWidth >= 780);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section
      id="home"
      className="relative min-h-screen md:min-h-[60vh] lg:min-h-[80vh] xl:min-h-screen overflow-x-auto overflow-y-hidden"
    >
      <div className="absolute inset-0 w-full min-h-[100vh]">
        {isMediumOrLarger ? (
          <AqarHome
            ref={heroRef}
            className="relative w-full h-[100vh] object-cover "
          />
        ) : (
          <img
            src={HeroMobile}
            ref={heroRef}
            alt="Background"
            className="relative w-full h-full  object-cover lg:h-auto "
          />
        )}
      </div>

      <div className="relative flex flex-col items-center justify-center">
        <div className="mx-4 my-4 text-center text-black">
          <h1
            ref={titleRef}
            className="font-sans text-white text-pretty text-[32px] sm:text-[28px] md:text-[40px] lg:text-[44px] xl:text-[50px] 2xl:text-[54px] uppercase mt-20 lg:mt-24 drop-shadow-md font-extrabold"
          >
            {strings.heroTitle1}
            <span className="font-praise italic lowercase">
              {strings.herospan}
            </span>
            {strings.heroTitle2}
          </h1>
        </div>
        <div className="text-center text-black">
          <h2
            ref={subtitleRef}
            className="font-serif md:px-20 lg:px-12 text-pretty text-[0.9rem] sm:text-[1rem] md:text-[1.5rem] lg:text-[1.9rem] xl:text-[2.3rem] capitalize -mt-5"
          >
            {strings.heroSubtitle}
          </h2>
        </div>
        <div className="py-2 text-center text-black">
          <p
            ref={descriptionRef}
            className="font-sans px-10 md:px-40 lg:px-40 text-pretty text-[0.5rem] sm:text-[0.7rem] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.3rem]"
          >
            {strings.heroDescription}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
