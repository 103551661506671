import React, { useEffect, useRef, useState } from "react";

const LanguageDropdown = () => {
    const [selected, setSelected] = useState("EN");
    const [isOpen, setIsOpen] = useState(false);
    const actRef = useRef();

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        setSelected(option);
        setIsOpen(false); // Close dropdown after selection
    };

    const handleClose = () => {
        setIsOpen(false);
    }
    const handleClickOutside = (e) => {
        if (actRef.current && !actRef.current.contains(e.target)) {
            handleClose();
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={actRef} className="relative inline-block text-left">
            <button
                type="button"
                className=" py-1 w-12 xl:w-16 font-bold rounded-md  focus:outline-none"
                onClick={toggleDropdown}
            >
                {selected}
                <svg
                    className="w-5 h-5 inline ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 9.586l3.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>

            {isOpen && (
                <div
                    className="absolute font-bold right-0 mt-2 w-12 xl:w-16  rounded-md "
                    role="menu"
                >
                    <button
                        onClick={() => handleSelect("EN")}
                        className={`block px-4 py-2 text-sm ${selected === "EN" ? " " : "text-gray-700"
                            }`}
                    >
                        EN
                    </button>
                    <button
                        onClick={() => handleSelect("AR")}
                        className={`block px-4 py-2 text-sm ${selected === "AR" ? " " : "text-gray-700"
                            }`}
                    >
                        AR
                    </button>
                </div>
            )}
        </div>
    );
};

export default LanguageDropdown;
