import React, { useEffect, useState } from "react";
import strings from "../strings";
import about1 from "../assets/Images/about1.png"
import about2 from '../assets/Images/about2.png'
import about3 from '../assets/Images/about3.png'
import about4 from '../assets/Images/about4.png'

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Function to handle screen resizing
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 780);
  };

  // UseEffect to add and clean up the resize event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="relative w-full max-w-[90rem] mx-auto p-4 mt-10 md:mt-12 lg:mt-16">
      <div className="relative w-full h-full  overflow-hidden">
        {/* SVG Background */}
        <svg
          className="absolute h-full w-full"
          viewBox={isMobile ? "0 0 804 1177" : "0 0 1439 782"}
          preserveAspectRatio="none"
        >
          <defs>
            <filter
              id="filter0_d_3_498"
              x={isMobile ? "-4.9" : "0.0999985"}
              y={isMobile ? "0.0999985" : "0.834862"}
              width={isMobile ? "808.8" : "1452.8"}
              height={isMobile ? "1176.8" : "780.8"}
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="24.45" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_3_498"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_3_498"
                result="shape"
              />
            </filter>
          </defs>
          <path
            d={
              isMobile
                ? "M44 158.426C44 150.711 45.7853 143.101 49.2162 136.191L79.7147 74.7646C88.1593 57.7566 105.509 47 124.498 47H705C732.614 47 755 69.3858 755 97V1064.3C755 1071.33 753.515 1078.29 750.642 1084.71L745.407 1096.42C737.357 1114.41 719.482 1126 699.765 1126H94C66.3858 1126 44 1103.61 44 1076V158.426Z"
                : "M49 164.109C49 149.395 55.4812 135.428 66.7175 125.927L145.221 59.5532C154.247 51.9219 165.684 47.7348 177.503 47.7348H1354C1381.61 47.7348 1404 70.1206 1404 97.7349V674.106C1404 690.344 1396.11 705.571 1382.85 714.942L1373.47 721.57C1365.03 727.533 1354.95 730.735 1344.62 730.735H99C71.3857 730.735 49 708.349 49 680.735V164.109Z"
            }
            fill="white"
            filter="url(#filter0_d_3_498)"
          />
        </svg>
        <div className="relative z-10 flex flex-col lg:flex-row py-10 px-10 md:px-16 lg:px-24 lg:py-20 justify-center items-center align-middle">
          <div className="w-[100%]  h-[100%] lg:w-[70%] p-4 justify-center items-center content-center pt-10 lg:pt-0  ">
            <div>
              <div>
                <div className="mb-1 w-16 h-[0.15rem] bg-[#1189CC] rounded-[20px]"></div>
                <h2 className="text-2xl font-bold mb-4 relative z-20 text-left">
                  {strings.aboutUsTitle}
                </h2>
              </div>
              <p className="font-sans leading-6 text-justify text-[0.65rem] md:text-[0.8rem] lg:text-[1.2rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                {strings.aboutUsDescription}
              </p>
              <div className="flex gap-6 pt-4">
                <div className="w-[30%] lg:w-[35%]">
                  <p className="font-sans leading-6 text-justify text-[0.65rem] md:text-[0.8rem] lg:text-[1.2rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                    {strings.aboutUsDescription2}
                  </p>
                  <div className="">
                    <img src={about1} alt="" className="w-full" />
                  </div>
                </div>
                <div className="w-[70%] lg:w-[65%]">
                  <img src={about2} alt="" className="w-full" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[100%] lg:w-[30%] p-4 flex flex-col iend h-full  content-center ">
            <img
              src={about3}
              alt="About Us"
              className=" object-cover size-50 sm:size-min md:size-min lg:size-min"
            />
            <div className="flex justify-end">
              <img
                src={about4}
                alt="About Us"
                className=" object-cover size-50 sm:size-min md:size-min lg:size-min"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
