import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaPlus, FaMinus } from "react-icons/fa";
// import faqImage from "../assets/FAQicon.svg"; // Adjust the path to your image
import faqImage from "../assets/FrequentlyAskedQuestions.png"; // Adjust the path to your image
import strings from "../strings";

gsap.registerPlugin(ScrollTrigger);

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  const answerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      gsap.to(answerRef.current, {
        height: "auto",
        duration: 0.3,
        ease: "power2.inOut",
      });
    } else {
      gsap.to(answerRef.current, {
        height: 0,
        duration: 0.3,
        ease: "power2.inOut",
      });
    }
  }, [isOpen]);

  return (
    <div
      className="border rounded-lg lg:rounded-xl p-4 lg:p-6 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">{question}</h3>
        {isOpen ? <FaMinus /> : <FaPlus />}
      </div>
      <div ref={answerRef} className="overflow-hidden mt-2">
        <p className="text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const faqRef = useRef(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    gsap.fromTo(
      faqRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power4.out",
        scrollTrigger: {
          trigger: faqRef.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  const faqs = [
    {
      question: "What is 360Aqar?",
      answer:
        "360Aqar is a cloud-based web application designed to enhance real estate marketing with immersive VR tours, interactive customer engagement tools, and integrated marketing solutions, tailored for Saudi developers.",
    },
    {
      question: "How can 360Aqar benefit my real estate business?",
      answer:
        "With 360Aqar, you can offer stunning virtual tours that attract more clients, gather valuable customer insights for better targeting, and streamline your marketing efforts through automated email campaigns and easy meeting scheduling.",
    },
    {
      question: "Is 360Aqar compliant with Saudi regulations?",
      answer:
        "Yes, 360Aqar is fully compliant with Saudi Arabian real estate and data protection regulations, ensuring your business operates within legal standards.",
    },
    {
      question: "What if I don’t want to use kiosks?",
      answer:
        "No problem! The web app can be accessed via any touch screen device, allowing you to engage customers and gather data through multiple channels without relying solely on kiosks.",
    },
  ];

  return (
    <section id="faq" className="py-16 px-6 lg:px-20 bg-white " ref={faqRef}>
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row md:gap-10">
        <div className="relative flex flex-col items-center justify-center md:gap-8 md:w-[60%] xl:w-[50%]" >
          <h2 className="text-center md:h-[20%] md:text-left  text-[1.8rem] md:text-[2.1rem] lg:text-[2.2rem] xl:text-[2.5rem] text-black uppercase sm:mb-0 my-4 xl:mt-8 font-serif font-bold">
            {/* Section Title */}
            Frequently
            <span className="text-[#1189CC]"> Asked </span>
            Questions
          </h2>
          <div className="flex items-center justify-center  w-full md:w-[80%] pb-10 ">
            <img src={faqImage} alt="FAQ" className="w-[55%] md:w-full lg:scale-90  " />
          </div>
        </div>
        <div className=" flex flex-col gap-2 justify-center lg:justify-between items-center w-full md:w-[40%] xl:w-[50%] lg:my-8 xl:my-16">

          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => toggleFAQ(index)}
            />
          ))}

        </div>
      </div>
    </section>
  );
};

export default FAQSection;
