import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import logo1 from "../assets/Images/Logo1.svg";
import logo2 from "../assets/Images/Logo2.svg";
import logo3 from "../assets/Images/Logo3.svg";
import logo4 from "../assets/Images/Logo4.svg";
import logo5 from "../assets/Images/Logo5.svg";
import Marquee from "react-fast-marquee";
// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

const TrustedBy = () => {
  const trustedRef = useRef(null);
  const logosRef = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      trustedRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "sine",
      }
    );

    logosRef.current.forEach((logo, index) => {
      gsap.fromTo(
        logo,
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: "sine",
          delay: index * 0.2,
        }
      );
    });
  }, []);

  return (
    <section
      className="py-16 px-12 lg:px-20 bg-white rounded-lg  overflow-hidden"
      ref={trustedRef}
    >
      <div className="relative max-w-full mx-auto text-center  mb-10 ">
        <h2 className=" text-[1.8rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem] font-semibold font-serif uppercase">
          Trusted by <span className="text-[#1189CC]">Leading Real Estate</span>{" "}
          Agencies
        </h2>
        <div className="absolute w-[25%] md:w-[18%]  lg:w-[12%]  xl:w-[8%]  2xl:w-[6%] left-[37.5%] md:left-[41%]  lg:left-[44%]  xl:left-[46%]  2xl:left-[47%]  h-[0.15rem]  bg-[#1189CC] rounded-[20px]"></div>
      </div>
      <div className="relative content-center items-center ">
        <Marquee
          autoFill
          play
          speed={50}
          gradient
          gradientColor="white"
          gradientWidth={100}
          className="my-10 lg:my-16"
        >
          <img
            src={logo1}
            alt="Logo 1"
            className="h-16 lg:h-24 mx-2 md:mx-10"
          />
          <img
            src={logo2}
            alt="Logo 2"
            className="h-16 lg:h-24 mx-2 md:mx-10"
          />
          <img
            src={logo3}
            alt="Logo 3"
            className="h-16 lg:h-24 mx-2 md:mx-10"
          />
          <img
            src={logo4}
            alt="Logo 4"
            className="h-16 lg:h-24 mx-2 md:mx-10"
          />
          <img
            src={logo5}
            alt="Logo 5"
            className="h-16 lg:h-24 mx-2 md:mx-10"
          />
        </Marquee>
      </div>
    </section>
  );
};

export default TrustedBy;
