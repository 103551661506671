import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import strings from "../strings"; // Adjust the path if necessary
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

const Subscription = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const packageRefs = useRef([]);
  const buttonRefs = useRef([]);

  const [isMediumOrLarger, setIsMediumOrLarger] = useState(
    window.innerWidth >= 1020
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMediumOrLarger(window.innerWidth >= 1020);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrentSlide(next),
    appendDots: (dots) => (
      <div>
        <ul
          style={{
            margin: "-4px",
            padding: "4px",
            display: "flex",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: i === currentSlide ? "25px" : "10px",
          height: i === currentSlide ? "8px" : "10px",
          borderRadius: i === currentSlide ? "15px" : "50%",
          background: i === currentSlide ? "#084466" : "rgba(8, 68, 102, 0.15)",
          display: "inline-block",
        }}
      ></div>
    ),
  };

  useEffect(() => {
    packageRefs.current.forEach((pkg, index) => {
      gsap.fromTo(
        pkg,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power4.out",
          scrollTrigger: {
            trigger: pkg,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  useEffect(() => {
    buttonRefs.current.forEach((button) => {
      const hoverTween = gsap.to(button, {
        scale: 1.1,
        duration: 0.2,
        ease: "power4.inOut",
        paused: true,
        onStart: () => {
          gsap.to(button, { boxShadow: "0 0 10px rgba(8, 68, 102, 0.6)" });
        },
        onReverseComplete: () => {
          gsap.to(button, { boxShadow: "none" });
        },
      });

      button.addEventListener("mouseenter", () => hoverTween.play());
      button.addEventListener("mouseleave", () => hoverTween.reverse());
      button.addEventListener("click", () => {
        gsap.to(button, {
          scale: 0.9,
          duration: 0.1,
          ease: "power4.out",
          onComplete: () => {
            gsap.to(button, { scale: 1, duration: 0.1, ease: "power4.in" });
          },
        });
      });
    });
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      packageRefs.current.forEach((pkg) => {
        gsap.fromTo(
          pkg,
          { opacity: 0, y: 100 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            ease: "power4.out",
            scrollTrigger: {
              trigger: pkg,
              start: "top 90%",
              end: "bottom 10%",
              toggleActions: "play none none reverse",
              scrub: true,
            },
          }
        );
      });
    }
  }, []);

  const [checkedOptions, setCheckedOptions] = useState([]);

  const handleCheckboxChange = (option) => {
    if (checkedOptions.includes(option)) {
      setCheckedOptions(checkedOptions.filter((item) => item !== option));
    } else {
      setCheckedOptions([...checkedOptions, option]);
    }
  };

  const addOnPrices = {
    vrHeadset: 3500,
    kiosk: 8500,
  };

  const getProfessionalPlanPrice = () => {
    const basePrice = 9400; // Monthly price for Professional Plan
    const annualPrice = 101520; // Annual price for Professional Plan

    const selectedAddOnsTotal = checkedOptions.reduce(
      (total, option) => total + addOnPrices[option],
      0
    );

    const monthlyTotal = basePrice + selectedAddOnsTotal;
    const annualTotal = annualPrice + selectedAddOnsTotal;

    return isMonthly2 ? monthlyTotal : annualTotal;
  };

  const [isMonthly1, setIsMonthly1] = useState(true);
  const handleToggle1 = (option) => {
    setIsMonthly1(option === 'monthly1');
  };

  const [isMonthly2, setIsMonthly2] = useState(true);
  const handleToggle2 = (option) => {
    setIsMonthly2(option === 'monthly2');
  };

  return (
    <section id="pricing" className="mt-14 py-8  ">

      <div className="max-w-7xl mx-auto text-center mb-4 lg:mb-8">
        <h2 className="text-[1.8rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem] font-bold py-2 font-serif uppercase">
          {strings.subscriptionTitle}
        </h2>
        <p className="text-black/50 text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">{strings.subscriptionDescription}</p>
      </div>
      <div className="w-full bg-gradient-to-br from-[#F4F8FC] to-[#ffffff] py-12">
        {isMediumOrLarger ? (
          <div
            className="flex flex-row items-start space-y-0 
          justify-evenly mt-20 mb-10 mx-8 xl:mx-16 gap-6 2xl:mx-20 "
          >
            {strings.packages.map((pkg, index) => (
              <div
                key={index}
                ref={(el) => (packageRefs.current[index] = el)}
                className={`w-[100%] h-[40rem] max-w-sm flex flex-col justify-between p-4 rounded-3xl shadow-lg ${pkg.isHighlighted
                  ? "bg-gradient-to-br from-[#1189CC] to-[#084466] text-white"
                  : "bg-white"
                  } transition-transform transform hover:scale-105`}
              >
                <h3
                  className={`text-lg font-light font-sans mt-5 text-center ${pkg.isHighlighted ? "text-white" : "text-black/50"
                    }`}
                >
                  {pkg.name}
                </h3>
                {pkg.name === "Basic Plan" && (
                  <>
                    <div className="text-center h-[62px]">
                      {isMonthly1 ? (
                        <div>
                          <p className="text-xl font-bold">5,900 SAR/month</p>
                        </div>
                      ) : (
                        <div className="">
                          <p className="text-xl font-bold">63,720 SAR/Year</p>
                          <p className="text-sm text-gray-600">(10% discount applied)</p>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 justify-center mb-4">
                      <button
                        className={`px-4 py-2 border ${isMonthly1 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                        onClick={() => handleToggle1('monthly1')}
                      >
                        Monthly
                      </button>
                      <button
                        className={`px-4 py-2 border ${!isMonthly1 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                        onClick={() => handleToggle1('annually1')}
                      >
                        Annually
                      </button>
                    </div>
                  </>
                )}
                {pkg.name === "Professional Plan" && (
                  <>
                    <div className="text-center h-[62px]">
                      {isMonthly2 ? (
                        <div>
                          <p className="text-xl font-bold">{getProfessionalPlanPrice()} SAR/month</p>
                        </div>
                      ) : (
                        <div className="">
                          <p className="text-xl font-bold">{getProfessionalPlanPrice()} SAR/Year</p>
                          <p className="text-sm text-gray-300">(10% discount applied)</p>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2 justify-center mb-4">
                      <button
                        className={`px-4 py-2 border ${isMonthly2 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                        onClick={() => handleToggle2('monthly2')}
                      >
                        Monthly
                      </button>
                      <button
                        className={`px-4 py-2 border ${!isMonthly2 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                        onClick={() => handleToggle2('annually2')}
                      >
                        Annually
                      </button>
                    </div>
                  </>
                )}
                {pkg.name === "Enterprise Plan" && (
                  <ul className={`list-disc pl-4 ${pkg.isHighlighted ? ' marker:text-gray-300' : ' marker:text-black'}`}>
                    <li>Custom Solutions for Your Needs
                      <ul className="list-square pl-4">
                        <li>Tailored immersive experiences and analytics services.</li>
                        <li>Flexible options for VR and 360-degree tours.</li>
                      </ul>
                    </li>
                  </ul>
                )}
                <ul className={`text-left mb-4 list-disc space-y-5  pl-4 ${pkg.isHighlighted ? 'marker:text-gray-300' : 'marker:text-black'}`}>
                  {pkg.features.map((feature, i) => (

                    <li>
                      <span className="font-semibold">{feature.title}</span>
                      <span>{feature.disc}</span>
                    </li>
                  ))}
                </ul>
                {pkg.name === "Professional Plan" && (
                  <ul className="list-disc  marker:text-gray-300 pl-4">
                    <li>Optional Add-ons:</li>
                    <div className="text-center">
                      <div className="flex justify-center">
                        <input
                          type="checkbox"
                          id="vrHeadset"
                          checked={checkedOptions.includes('vrHeadset')}
                          onChange={() => handleCheckboxChange('vrHeadset')}
                          className="mr-2"
                        />
                        <label htmlFor="vrHeadset" className="text-left"><span>VR Headset: </span> Enhance the immersive experience. “3,500 SAR”</label>
                      </div>
                      <div className="flex justify-center mt-2">
                        <input
                          type="checkbox"
                          id="kiosk"
                          checked={checkedOptions.includes('kiosk')}
                          onChange={() => handleCheckboxChange('kiosk')}
                          className="mr-2"
                        />
                        <label htmlFor="kiosk" className="text-left"><span>KIOSK: </span> Provide an interactive display for property presentations. “8,500 SAR”</label>
                      </div>
                    </div>
                  </ul>
                )}
                <div className="flex justify-center ">
                  <button
                    ref={(el) => (buttonRefs.current[index] = el)}
                    className={`py-3 mt-12 mb-4 px-8 rounded-full  ${pkg.isHighlighted
                      ? "bg-white text-[#084466] hover:bg-[rgb(222,238,246)]"
                      : "bg-[#084466] text-white hover:bg-[#072e54]"
                      } transition-colors`}
                  >
                    {(pkg.name === "Enterprise Plan") ? (

                      <a href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07">Contact Us</a>
                    ) :
                      (
                        "Get Started"
                      )}

                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mx-4 subscription-mobile">
            <Slider {...settings} className="w-full mx-auto">
              {strings.packages.map((pkg, index) => (
                <div
                  key={index}
                  ref={(el) => (packageRefs.current[index] = el)}
                  className={`w-[100%] max-w-sm h-[40rem] flex flex-col justify-between p-4 rounded-3xl shadow-lg ${pkg.isHighlighted
                    ? "bg-gradient-to-br from-[#1189CC] to-[#084466] text-white"
                    : "bg-white"
                    } transition-transform transform`}
                >
                  <h3
                    className={`text-lg font-light font-sans mt-5 text-center ${pkg.isHighlighted ? "text-white" : "text-black/50"
                      }`}
                  >
                    {pkg.name}
                  </h3>
                  {pkg.name === "Basic Plan" && (
                    <>
                      <div className="text-center h-[62px]">
                        {isMonthly1 ? (
                          <div>
                            <p className="text-xl font-bold">5,900 SAR/month</p>
                          </div>
                        ) : (
                          <div className="">
                            <p className="text-xl font-bold">63,720 SAR/Year</p>
                            <p className="text-sm text-gray-600">(10% discount applied)</p>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2 justify-center mb-4">
                        <button
                          className={`px-4 py-2 border ${isMonthly1 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                          onClick={() => handleToggle1('monthly1')}
                        >
                          Monthly
                        </button>
                        <button
                          className={`px-4 py-2 border ${!isMonthly1 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                          onClick={() => handleToggle1('annually1')}
                        >
                          Annually
                        </button>
                      </div>
                    </>
                  )}
                  {pkg.name === "Professional Plan" && (
                    <>
                      <div className="text-center h-[62px]">
                        {isMonthly2 ? (
                          <div>
                            <p className="text-xl font-bold">{getProfessionalPlanPrice()} SAR/month</p>
                          </div>
                        ) : (
                          <div className="">
                            <p className="text-xl font-bold">{getProfessionalPlanPrice()} SAR/Year</p>
                            <p className="text-sm text-gray-300">(10% discount applied)</p>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-2 justify-center mb-4">
                        <button
                          className={`px-4 py-2 border ${isMonthly2 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                          onClick={() => handleToggle2('monthly2')}
                        >
                          Monthly
                        </button>
                        <button
                          className={`px-4 py-2 border ${!isMonthly2 ? 'bg-[#084466] border-white text-white' : 'bg-gray-200 text-gray-700 border-[#084466]'} rounded-md`}
                          onClick={() => handleToggle2('annually2')}
                        >
                          Annually
                        </button>
                      </div>
                    </>
                  )}
                  {pkg.name === "Enterprise Plan" && (
                    <ul className={`list-disc pl-4 ${pkg.isHighlighted ? ' marker:text-gray-300' : ' marker:text-black'}`}>
                      <li>Custom Solutions for Your Needs
                        <ul className="list-square pl-4">
                          <li>Tailored immersive experiences and analytics services.</li>
                          <li>Flexible options for VR and 360-degree tours.</li>
                        </ul>
                      </li>
                    </ul>
                  )}
                  <ul className={`text-left mb-4 list-disc space-y-5  pl-4 ${pkg.isHighlighted ? 'marker:text-gray-300' : 'marker:text-black'}`}>
                    {pkg.features.map((feature, i) => (

                      <li>
                        <span className="font-semibold">{feature.title}</span>
                        <span>{feature.disc}</span>
                      </li>
                    ))}
                  </ul>
                  {pkg.name === "Professional Plan" && (
                    <ul className="list-disc  marker:text-gray-300 pl-4">
                      <li>Optional Add-ons:</li>
                      <div className="text-center">
                        <div className="flex justify-center">
                          <input
                            type="checkbox"
                            id="vrHeadset"
                            checked={checkedOptions.includes('vrHeadset')}
                            onChange={() => handleCheckboxChange('vrHeadset')}
                            className="mr-2"
                          />
                          <label htmlFor="vrHeadset" className="text-left"><span>VR Headset: </span> Enhance the immersive experience. “3,500 SAR”</label>
                        </div>
                        <div className="flex justify-center mt-2">
                          <input
                            type="checkbox"
                            id="kiosk"
                            checked={checkedOptions.includes('kiosk')}
                            onChange={() => handleCheckboxChange('kiosk')}
                            className="mr-2"
                          />
                          <label htmlFor="kiosk" className="text-left"><span>KIOSK: </span> Provide an interactive display for property presentations. “8,500 SAR”</label>
                        </div>
                      </div>
                    </ul>
                  )}
                  <div className="flex justify-center ">
                    <button
                      ref={(el) => (buttonRefs.current[index] = el)}
                      className={`py-3 mt-12 mb-4 px-8 rounded-full  ${pkg.isHighlighted
                        ? "bg-white text-[#084466] hover:bg-[rgb(222,238,246)]"
                        : "bg-[#084466] text-white hover:bg-[#072e54]"
                        } transition-colors`}
                    >
                      {(pkg.name === "Enterprise Plan") ? (

                        <a href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07">Contact Us</a>
                      ) :
                        (
                          "Get Started"
                        )}

                    </button>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>

    </section>
  );
};

export default Subscription;
