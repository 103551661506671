import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import partnersliderimage1 from "../assets/Images/Our Strategic Partners1.svg";
import partnersliderimage2 from "../assets/Images/Our Strategic Partners2.svg";
import strate1 from '../assets/strate1.jpg'
import strate2 from '../assets/strate2.jpg'
import strate3 from '../assets/strate3.png'
import strate4 from '../assets/strate4.png'
import strate5 from '../assets/strate5.png'
import Marquee from "react-fast-marquee";

const Partners = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    accessibility: true,
    adaptiveHeight: false,
    appendDots: dots => (
      <div
        style={{
          // backgroundColor: "#ddd",
          // borderRadius: "10px",
          // padding: "10px"
        }}
      >
        <ul style={{ margin: "0px", color: "white" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          autoplay: true,
          arrows: true,
          autoplaySpeed: 2000,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          arrows: false,
          autoplaySpeed: 2000,
          dots: true,
        },
      },
    ],
  };

  const images = [
    strate1,
    strate2,
    strate3,
    strate4,
    strate5,
  ];

  return (
    <div className="w-full text-center flex flex-col items-center overflow-hidden">
      <div className="relative flex flex-col justify-center   items-center">
        {/* <div className=" rounded-[30px] sm:rounded-[30px] md:rounded-[45px] lg:rounded-[55px] xl:rounded-[65px] border-2 border-blue-200 pt-0 md:pt-10 pb-4 text-center bg-white w-[85%] justify-center content-center z-50 md:z-30">
          <div className="flex justify-center top-0 mt-2 content-start align-top  ">
            <svg
              className="w-10 h-10 md:w-[123px] md:h-[111px]"
              viewBox="0 0 123 111"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.05 110C25.2 110 49.4 103.945 49.4 61.5563V13.1126C49.4 5.54327 44.8262 0.898734 37.3 1.00168H13.1C5.5375 1.00168 1 5.54327 1 12.943V49.4454C1 57.0147 5.5375 61.5563 13.1 61.5563C19.15 61.5563 19.15 61.5563 19.15 67.6118V73.6672C19.15 79.7227 13.1 85.7781 7.05 85.7781C1 85.7781 1 85.8266 1 92.0213V103.945C1 110 1 110 7.05 110ZM79.65 110C97.8 110 122 103.945 122 61.5563V13.1126C122 5.54327 117.42 0.898734 109.9 1.00168H85.7C78.1375 1.00168 73.6 5.54327 73.6 12.943V49.4454C73.6 57.0147 78.1375 61.5563 85.7 61.5563H90.2375C90.2375 75.1811 91.75 85.7781 73.6 85.7781V103.945C73.6 110 73.6 110 79.65 110Z"
                stroke="#1189CC"
                stroke-opacity="0.2"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="  -mt-4  z-50 md:-mt-2 ">
            <h3 className=" text-[1.4rem] sm:text-[1.4rem] lg:text-[2.5rem] xl:text-[3rem] 2xl:text-[3.5rem] font-bold font-serif text-blue-500 ">
              Success Stats:
            </h3>
          </div>

          <p className="text-[0.7rem] sm:text-[0.7rem] lg:text-[1.1rem] xl:text-[1.3rem] 2xl:text-[2rem] text-black font-thin">
            Over 5,000 properties listed last month alone!
          </p>
        </div> */}
        <div className="relative -top-10 md:-top-5    flex flex-col justify-center py-16 rounded-lg w-screen z-40">
          <h3 className="text-[1.5rem] sm:text-[1.6rem] md:text-[2rem] lg:text-[2.5rem] xl:text-[3rem] 2xl:text-[3.5rem] font-semibold uppercase font-serif text-black mb-4">
            Our <span className="text-blue-400">Strategic</span> Partners
          </h3>
          <p className="mx-auto max-w-5xl px-8 text-[0.8rem] sm:text-[0.9rem] md:text-[1.2rem] lg:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[2rem] text-black  font-thin">
            Empowered by strategic partnerships with global industry innovators, 360 Aqar significantly boosts its immersive tech capabilities, offering enhanced interactive virtual experiences to our valued Real Estate clients.
          </p>
          <div className="mt-16 w-full strategic">
            <div className="grid grid-cols-2 px-4 md:px-0 gap-2 md:grid-cols-5 justify-center items-center">
              {images.map((imgSrc, index) => (
                <div key={index} className="flex justify-center items-center lg:p-4">
                  <img
                    src={imgSrc}
                    alt={`Logo ${index + 1}`}
                    className="w-full h-auto object-contain max-h-52"  // Maintain aspect ratio with object-contain
                  />
                </div>
              ))}
            </div>
            {/* <Slider {...settings}>
              {images.map((imgSrc, index) => (
                <div key={index} className="px-4">
                  <div className="bg-white flex items-center rounded-lg overflow-hidden h-60 lg:h-[25rem] ">
                    <img
                      src={imgSrc}
                      alt={`Partner ${index + 1}`}
                      className="w-full scale-75  object-cover lg:py-4 px-2"
                    />
                  </div>
                </div>
              ))}
            </Slider> */}
            {/* <Marquee
              autoFill
              play
              speed={50}
              gradient
              gradientColor="white"
              gradientWidth={100}
              className="my-10 lg:my-16"
            > */}
            {/* <div className="grid grid-cols-2 px-8 md:px-0 gap-2 md:grid-cols-5 justify-center items-center">
              <div className="flex justify-center items-center">
                <img
                  src={strate1}
                  alt="Logo 1"
                  className="w-full h-16 lg:h-24 mx-2 md:mx-10"
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={strate2}
                  alt="Logo 2"
                  className="w-full h-16 lg:h-24 mx-2 md:mx-10"
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={strate3}
                  alt="Logo 3"
                  className="w-full h-16 lg:h-24 mx-2 md:mx-10"
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={strate4}
                  alt="Logo 4"
                  className="w-full h-16 lg:h-24 mx-2 md:mx-10"
                />
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={strate5}
                  alt="Logo 5"
                  className="w-full h-16 lg:h-24 mx-2 md:mx-10"
                />
              </div>
            </div> */}
            {/* </Marquee> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
