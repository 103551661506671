import React, { useState } from "react";
import background from "../assets/Images/contact-bg.webp"; // Adjust the path to your background image
import axios from "axios";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "http://77.37.122.29:5002/send",
        formData
      );
      setResponseMessage("Email sent successfully!");
    } catch (error) {
      setResponseMessage("Failed to send email.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      id="contact"
      className="py-16 px-6 lg:px-20 mx-4 lg:mx-10 bg-cover bg-center rounded-[25px] shadow-lg relative"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="text-white flex flex-col justify-between">
          <h2 className="text-xl font-bold">Be the First to Experience 360 Aqar</h2>
          <h2 className="text-xl font-bold">Don't Miss Out on Our Launch!</h2>
          <div>Stay ahead in the real estate market with our cutting-edge VR and 360-degree property marketing tools.
            By subscribing, you'll be the first to know when 360 Aqar goes live, giving you an exclusive edge.</div>
          <div>
            <span>Exclusive Updates: </span>
            Get the latest news and features directly to your inbox.
          </div>
          <div>
            <span>Early Access: </span>
            Be among the first to explore our innovative tools.
          </div>
          <div>
            <span>Special Offers: </span>
            Receive launch-only discounts and benefits.
          </div>

          {/* <div className="flex items-center space-x-4">
            <FaPhoneAlt className="text-2xl" />
            <span>+91 9876543210</span>
          </div>
          <div className="flex items-center space-x-4">
            <FaEnvelope className="text-2xl" />
            <span>info@360aqar.com</span>
          </div>
          <div className="flex items-center space-x-4">
            <FaGlobe className="text-2xl" />
            <span>Location</span>
          </div> */}
        </div>
        <div className="bg-white py-4 px-8 rounded-3xl shadow-lg">
          <div className="py-2 font-semibold">Join the Future of Property Marketing. Subscribe Now!</div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <textarea
                name="message"
                placeholder="Type message here"
                className="w-full px-4 py-3 border rounded-lg focus:outline-none focus:border-blue-500 h-32 resize-none"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-[#1189CC] text-white py-3 rounded-full hover:bg-[#084466] transition duration-300 flex items-center justify-center"
              disabled={loading}
            >
              {loading ? <div className="spinner"></div> : "Submit"}
            </button>
          </form>
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
