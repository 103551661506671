import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import strings from "../strings"; // Adjust the path if necessary
import boyEmoji from '../assets/sneaky-boy-vector-579427__1_-removebg-preview.png'

// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

const TryDemo = () => {
  const demoRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Section animation
    gsap.fromTo(
      demoRef.current,
      { opacity: 0.45, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "bounce",
        stagger: true,
      }
    );

    // Button hover animation
    const hoverTween = gsap.to(buttonRef.current, {
      scale: 1.05,
      duration: 0.3,
      ease: "power4.inOut",
      paused: true,
    });

    buttonRef.current.addEventListener("mouseenter", () => hoverTween.play());
    buttonRef.current.addEventListener("mouseleave", () =>
      hoverTween.reverse()
    );
  }, []);

  return (
    <section
      id="demo"
      className="px-4 py-4 lg:px-8 lg:py-8 mx-5 mt-10 bg-gradient-to-br from-[#1189CC] to-[#084466] text-white rounded-[0.9rem] lg:rounded-2xl shadow-lg "
      ref={demoRef}
    >
      <div className="max-w-7xl mx-auto flex flex-col gap-8 lg:gap-0 lg:flex-row justify-start lg:justify-between lg:items-center items-start relative">
        <div className="text-left text-pretty mb-6 sm:mb-2 lg:mb-0">
          <h2 className="text-[1rem] font-sans lg:text-xl mb-1 lg:mb-0">
            {strings.tryDemoText}
          </h2>
        </div>
        <div className="relative sm:text-left">
          <button
            ref={buttonRef}
            onClick={() =>
            (window.location.href =
              "https://real-estate-landing-three.vercel.app/")
            }
            className="fancy py-2 lg:py-2 px-8 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1.1rem] lg:text-lg overflow-hidden
            relative"
          >
            {strings.tryDemoButton}
          </button>
          <img src={boyEmoji} alt="" className="w-16 absolute -top-5 right-10 " />
        </div>
      </div>
    </section>
  );
};

export default TryDemo;
