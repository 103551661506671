import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import logo from '../assets/Images/Logos/Pngs/Final-07.png'
import ReactFlagsSelect from "react-flags-select";
import LanguageDropdown from "./LanguageDropdown";
import boyEmoji from '../assets/sneaky-boy-vector-579427__1_-removebg-preview.png'
import strings from "../strings";


const navLinks = [
  { title: "Home", href: "#home" },
  { title: "Features", href: "#howitwork" },
  { title: "Pricing", href: "#pricing" },
  { title: "About Us", href: "#about" },
  { title: "Contact Us", href: "#contact" },
  { title: " ", href: "#" },
  // { title: "Schedule Meeting", href: "https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07" },
  // { title: "See It Live", href: "https://real-estate-landing-three.vercel.app/" },

];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleLinkClick = (href) => {
    const section = document.querySelector(href);
    setIsOpen(false);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleLinkClickMobile = (href) => {
    const section = document.querySelector(href);
    setIsOpen(false);
    if (section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth' });
      }, 600);
    }
  };


  const [selected, setSelected] = useState('US');


  const menuVars = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: {
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  };

  const containerVars = {
    initial: {
      transition: {
        staggerChildren: 0.09,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.09,
        staggerDirection: 1,
      },
    },
  };

  return (
    <nav className="absolute top-0 left-0 w-full bg-transparent flex justify-center py-4 z-10 overflow-x-hidden max-w-screen ">
      <div className=" mx-auto flex  justify-between px-2 md:px-4 items-center pb-16">
        <div className="text-2xl font-bold text-black w-[30%] md:w-[22%] lg:w-[18%]">
          <img src={logo} alt="" className="w-[100%]" />
        </div>
        <div className="hidden md:flex items-center justify-center font-semibold text-sm md:text-[1rem] xl:text-lg gap-2 lg:gap-4 2xl:gap-8 w-[40%] lg:w-[50%] py-6">
          {navLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              onClick={(e) => {
                e.preventDefault();
                handleLinkClick(link.href);
              }}
              className={`text-gray-900 hover:text-gray-900 ${link.title === "See It Live" ? "fancy1 relative" : ""
                } ${link.title === "Schedule Meeting" ? "fancy1" : ""}`}
            >
              {link.title}
            </a>
          ))}
        </div>
        <div className="hidden md:flex relative sm:text-left w-[32%] xl:w-[30%] ">
          <div className="flex items-center">
            <LanguageDropdown />
          </div>
          <img src={boyEmoji} alt="" className="w-16 absolute -top-5 right-8  xl:right-[4rem]" />
          <button
            // ref={buttonRef}
            onClick={() =>
            (window.location.href =
              "https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07")
            }
            className="fancy1 text-gray-900 hover:text-gray-900 w-full lg:inline py-2 lg:py-2 xl:px-2 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1rem] overflow-hidden leading-4 "
          >
            {/* <a
              className="w-full text-nowrap"
              href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07"
            > */}
            Schedule Meeting
            {/* </a> */}
          </button>
          <button
            // ref={buttonRef}
            onClick={() =>
            (window.location.href =
              "https://real-estate-landing-three.vercel.app/")
            }
            className="fancy1 text-gray-900 hover:text-gray-900 w-full py-2 lg:py-2 xl:px-2 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1rem] overflow-hidden"
          >
            {strings.tryDemoButton}
          </button>
          {/* <img src={boyEmoji} alt="" className="w-16 absolute -top-5 right-[45%] md:right-20 lg:right-28 xl:right-40 2xl:right-48" /> */}
        </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-600 focus:outline-none"
          >
            <svg
              className="w-6 h-6 me-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="black"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="fixed left-0 top-0 w-full min-h-screen origin-top bg-gradient-to-br from-[#1189CC] to-[#084466] text-white p-10 z-100"
          >
            <div className="flex h-full flex-col ">
              <div className="flex justify-end ">
                <button
                  className="text-gray-600 focus:outline-none"
                  onClick={toggleMenu}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="white"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    ></path>
                  </svg>
                </button>
              </div>
              <motion.div
                variants={containerVars}
                initial="initial"
                animate="open"
                exit="initial"
                className="flex flex-col h-[70vh] justify-center text-center font-serif items-center gap-6 content-center"
              >
                {navLinks.map((link, index) => (
                  <div className="overflow-hidden" key={index}>
                    <MobileNavLink
                      title={link.title}
                      href={link.href}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickMobile(link.href);
                      }}
                      isBtn={link.title === "See It Live" ? true : false}
                    />
                  </div>
                ))}
                <div className=" rounded-lg text-xl max-h-fit relative">
                  <LanguageDropdown />
                </div>


                <div className="flex flex-col gap-4 md:hidden relative sm:text-left ">
                  <img src={boyEmoji} alt="" className="w-16 absolute top-11 right-20 lg:right-16 xl:right-[6rem]" />
                  <button
                    // ref={buttonRef}
                    onClick={() =>
                    (window.location.href =
                      "https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07")
                    }
                    className="fancy1 text-gray-900 hover:text-gray-900 w-full py-2 lg:py-2 xl:px-8 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1.1rem] overflow-hidden
            relative"
                  >
                    {/* <a
              className="w-full text-nowrap"
              href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07"
            > */}
                    Schedule Meeting
                    {/* </a> */}
                  </button>
                  <button
                    // ref={buttonRef}
                    onClick={() =>
                    (window.location.href =
                      "https://real-estate-landing-three.vercel.app/")
                    }
                    className="fancy1 text-gray-900 hover:text-gray-900 w-full py-2 lg:py-2 px-8 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1.1rem] overflow-hidden
            relative"
                  >
                    {strings.tryDemoButton}
                  </button>
                  {/* <img src={boyEmoji} alt="" className="w-16 absolute -top-5 right-[45%] md:right-20 lg:right-28 xl:right-40 2xl:right-48" /> */}
                </div>
                {/* <button
                    className="fancy1 text-4xl font-serif overflow-hidden"
                    onClick={handleLinkClick}
                  >
                    <a href="#demo">Try Demo</a>
                  </button> */}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;

const mobileLinkVars = {
  initial: {
    y: "30vh",
    transition: {
      duration: 0.5,
      ease: [0.37, 0, 0.63, 1],
    },
  },
  open: {
    y: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.7,
    },
  },
};

const MobileNavLink = ({ title, href, onClick, isBtn }) => {
  return (
    <motion.div
      variants={mobileLinkVars}
      className={`text-4xl uppercase  ${isBtn ? "text-white  justify-center" : ""
        }`}
    >
      {isBtn ? (
        <button className="fancy1 text-4xl font-serif overflow-hidden">
          <a href={href} onClick={onClick}>
            {title}
          </a>
        </button>
      ) : (
        <a href={href} onClick={onClick}>
          {title}
        </a>
      )}
    </motion.div>
  );
};
