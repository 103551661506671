// src/analytics.js
import ReactGA from "react-ga";

const TRACKING_ID = "G-7ESMFG6GQT"; // GA_ID
ReactGA.initialize(TRACKING_ID);

export const trackPageView = (page) => {
  ReactGA.pageview(page);
};

export default ReactGA;
