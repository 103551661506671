// strings.js

const strings = {
  // Hero Section
  heroTitle1: "Unlock the Future",
  herospan: " of ",
  heroTitle2: "Real Estate Marketing",
  heroSubtitle: "Transform Your Property Marketing with Immersive Presentation Tools",
  heroDescription: "360Aqar Web-App offers interactive property presentations for Real Estate developers and Marketing agencies, along with VR and 360 tools revolutionizing real estate marketing.",

  // Features Section
  // feature1: "Immersive VR and 360° Tours",
  feature1: "Immersive Property Showcases",
  feature1Description: [
    "Engage potential buyers with captivating 360 and VR tours, and dynamic interactive presentations. ",
    // "Offer an unparalleled virtual experience that captivates potential clients, allowing them to explore properties as if they were there in person.",
    // "Stand Out in the Market: ",
    // "Differentiate your listings with high-quality, immersive tours that attract more attention and drive higher engagement.",
  ],
  feature2: "Analytics and Insights",
  feature2Description: [
    "Gain valuable insights and enhance marketing strategies with comprehensive visitor analytics and retargeting tools. ",
    // "Collect crucial customer data such as preferences and feedback, enabling you to understand and target your audience better.",
    // "Maximize Event Impact: ",
    // "Use interactive kiosks at exhibitions and events to engage visitors, capture their details, and follow up effectively.",
  ],
  feature3: "Communication & Engagement",
  feature3Description: [
    "Boost engagement and streamline communication with direct WhatsApp messaging and easy meeting scheduling. ",
    // "Use collected data to send personalized email campaigns, ensuring your message reaches the right audience at the right time.",
    // "Streamlined Communication: ",
    // "Schedule one-on-one meetings and host webinars directly from the app, facilitating direct and personal interaction with potential buyers.",
  ],

  // How it works Section
  howIt: "How ",
  howItWorksblue: "360Aqar ",
  works: "Works",
  howItWorksDescription: "DescriptionDescriptionDescriptionDescription",
  work1: "Create Immersive Content",
  work1Description:
    "Upload property details and assets to generate stunning 360 and VR tours, and create interactive presentations that captivate your audience.",
  work2: "Analyze Visitor Insights",
  work2Description:
    "Utilize the comprehensive dashboard to track visitor interactions, collect valuable data, and build an email list for effective retargeting.",
  work3: "Engage and Connect",
  work3Description:
    "Facilitate seamless communication through direct Chatbot messaging and easy meeting scheduling with Candly, ensuring potential buyers can reach you effortlessly.",

  // Main Features Section
  mainFeaturesTitle1: "Our Magic ",
  mainFeaturesTitle2: "Tools",
  mainFeaturesSubTitle2: "VR Headsets and 360 Cameras",
  mainFeaturesDescription2: [
    "Cutting-Edge Technology: ",
    "Utilize the latest VR headsets and 360 cameras to create immersive and realistic property tours that captivate potential buyers.",
    "Enhanced Visualization: ",
    "Allow clients to experience properties in a completely new way, seeing every detail and visualizing themselves in the space.",
  ],
  // mainFeaturesSubTitle1: "Interactive VR Tour",
  // mainFeaturesDescription1: [
  //   "VR Walk-Throughs: Immersive 3D property tours. ",
  //   "Investor Attraction: Visualize future projects in VR.",
  //   "Global Exhibitions: Showcase in VR to worldwide buyers. ",
  //   "Metaverse Spaces: Engage in shared virtual property experiences.",
  // ],
  // mainFeaturesSubTitle2: "Interactive VR Tour",
  // mainFeaturesDescription2: [
  //   "VR Walk-Throughs: Immersive 3D property tours. ",
  //   "Investor Attraction: Visualize future projects in VR.",
  //   "Global Exhibitions: Showcase in VR to worldwide buyers. ",
  //   "Metaverse Spaces: Engage in shared virtual property experiences.",
  // ],
  // mainFeaturesSubTitle3: "Interactive VR Tour",
  // mainFeaturesDescription3: [
  //   "VR Walk-Throughs: Immersive 3D property tours. ",
  //   "Investor Attraction: Visualize future projects in VR.",
  //   "Global Exhibitions: Showcase in VR to worldwide buyers. ",
  //   "Metaverse Spaces: Engage in shared virtual property experiences.",
  // ],
  mainFeaturesSubTitle1: "Smart Web App",
  mainFeaturesDescription1: [
    "Seamless Integration: ",
    "Our web app seamlessly integrates with VR headsets and 360 cameras, making it easy to upload, edit, and publish immersive tours.",
    "User-Friendly Interface: ",
    "Designed with the user in mind, our web app provides an intuitive interface that simplifies the creation and management of virtual tours.",
  ],
  mainFeaturesSubTitle3: "Interactive Kiosks",
  mainFeaturesDescription3: [
    "Event Ready: ",
    "Deploy interactive kiosks at events and exhibitions to engage visitors, gather their data, and provide a memorable experience.",
    "Mobile Engagement: ",
    "Use kiosks in your sales office or at different locations to maximize reach and impact.",
  ],
  interactiveVrTool: "Interactive VR Tool",

  // Subscription Section
  subscriptionTitle: "Subscription Plans",
  subscriptionDescription: " ",
  packages: [
    {
      name: "Basic Plan",
      price1: "5,900 SAR/month",
      price2: "63,720 SAR/Year",
      price3: " ( 10% discount )",
      features: [
        { title: "Immersive Experiences:", disc: " Engage buyers with interactive, immersive presentations." },
        { title: "Buyer Insights:", disc: " Use our dashboard to track buyer interactions and preferences." },
        { title: "Engagement Tracking:", disc: " Automatically follow up with interested buyers to keep them engaged." },
      ],
      buttonText: "Get Started",
      isHighlighted: false,
    },
    {
      name: "Professional Plan",
      price1: "9,400 SAR/month",
      price2: "101,520 SAR/Year",
      price3: " ( 10% discount )",
      features: [
        { title: "Includes Basic Plan Features", disc: "" },
        { title: "360 Scanning Walkthroughs:", disc: " Up to 4 scans covering 1000 m²." },
        { title: "VR 3D Tours:", disc: " Up to 2 tours covering 500 m²." },
        // {title:'Optional Add-ons:', disc:" (Check box to be added and calculated once they choosen)"},

      ],
      buttonText: "Get Started",
      buttonText2: "Contact Us",
      isHighlighted: true,
    },
    {
      name: "Enterprise Plan",
      // price1: "5,900 SAR/month",
      // price2: "63,720 SAR/Year",
      // price3: "10% discount",
      features: [
        { title: "Contact Us: ", disc: " Schedule a meeting to discuss your needs and get a custom pricing plan. Let's create the perfect property marketing solution together." },
      ],
      buttonText: "Get Started",
      isHighlighted: false,
    },
  ],

  // ChallengeVsSolutions
  problem: "Problem ",
  vs: "vs",
  solution: " Solution",
  problemVsSolutionDescription: "As the Saudi real estate market evolves, traditional marketing struggles with low engagement and missed opportunities. 360 Aqar offers innovative solutions that boost engagement, drive sales, and provide key insights into buyer behavior",
  challengeArrayLeft: [
    {
      id: 1, titleBold: "Low Engagement, Low Sales ", disc: "Standard Listings Fail to capture buyer’s  attention."
    },
    {
      id: 2, titleBold: "Poor Visulization", disc: "Statics Images can’t show the full beauty & value of properties.",
    },
    {
      id: 3, titleBold: "Lack of Insights", disc: "Limited data on buyer behaviour and Preferences.",
    },
    {
      id: 4, titleBold: "Limited Reach", disc: "Traditional Marketing Struggles to attract a wide audience.",
    },
  ],
  challengeArrayRight: [
    { id: 1, titleBold: "Interactive Presentations, Faster Sales ", disc: "Engage buyers with immersive VR & 360 Degree experiences." },
    { id: 2, titleBold: "Detailed Visualisation ", disc: "Showcase properties in stunning detail, enhancing buyer understanding." },
    { id: 3, titleBold: "Customer Data Analysis ", disc: "Use our dashboard to track buyer interactions & Preferences." },
    { id: 4, titleBold: "Retargeting Potential Customers ", disc: "Automatically follow up with interested buyer to keep them engaged." },
  ],

  // Ask For Demo Section
  tryDemoText:
    "Experience the Future of Real Estate – Request Your Demo Today!",
  tryDemoButton: "See It Live",

  // About Us Section
  aboutUsTitle: "About Us",
  aboutUsDescription: [
    "Who We Are : ",
    "360Aqar is a pioneering real estate technology company, committed to transforming property marketing and management in Saudi Arabia. Our mission is to empower developers with advanced tools to enhance customer engagement and streamline operations.",
  ],
  aboutUsDescription2: [
    "Our Vision : ",
    "To be the premier provider of immersive real estate solutions in Saudi Arabia, driving innovation and setting new standards for property marketing and management.",
  ],
};

export default strings;
