import React, { useEffect, useRef } from "react";
import logo from "../assets/Images/Logos/Jpegs/Final-01.jpg"; // Adjust the path to your logo
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { AiFillInstagram, AiFillTikTok } from "react-icons/ai";
import { FaSquareXTwitter } from "react-icons/fa6";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import boyEmoji from '../assets/sneaky-boy-vector-579427__1_-removebg-preview.png'
import strings from "../strings";
import { Link } from "react-router-dom";

const navLinks = [
  { title: "Schedule Meeting", href: "https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07" },
  { title: "See It Live", href: "https://real-estate-landing-three.vercel.app/" },

];
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const demoRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Section animation
    gsap.fromTo(
      demoRef.current,
      { opacity: 0.45, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "bounce",
        stagger: true,
      }
    );

    // Button hover animation
    const hoverTween = gsap.to(buttonRef.current, {
      scale: 1.05,
      duration: 0.3,
      ease: "power4.inOut",
      paused: true,
    });

    buttonRef.current.addEventListener("mouseenter", () => hoverTween.play());
    buttonRef.current.addEventListener("mouseleave", () =>
      hoverTween.reverse()
    );
  }, []);



  return (
    <footer className="bg-white py-10 w-full overflow-x-hidden">
      <div className="w-full mx-auto px-6 lg:px-20 ">
        <div className="flex flex-col md:flex-row justify-center items-center gap-10">
          <div className="w-full md:w-[25%]">
            <div className="w-[50%]">
              <img src={logo} alt="Logo" className="w-20 " />
            </div>
            <p className="text-gray-500">
              Tagline here Tagline here Tagline here
            </p>
          </div>
          <div className="w-full md:w-[50%]">
            <ul className="grid grid-cols-1 md:grid-cols-2  gap-2 md:gap-4 lg:gap-8 text-gray-700">
              <li>
                <a href="#howitwork" className="hover:text-gray-900">
                  How It Works
                </a>
              </li>
              <li>
                <a href="null" className="hover:text-gray-900">
                  Challenges & Solutions
                </a>
              </li>
              <li>
                <a href="#pricing" className="hover:text-gray-900">
                  Pricing
                </a>
              </li>
              <li>
                <a href="#about" className="hover:text-gray-900">
                  About Us
                </a>
              </li>
              <li>
                <a href="#faq" className="hover:text-gray-900">
                  FAQs
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:text-gray-900">
                  Contact Us
                </a>
              </li>

            </ul>
          </div>
          <div className="w-full relative md:w-[25%] flex flex-col gap-8 md:gap-4 lg:gap-8"
            ref={demoRef}
          >
            {/* {navLinks.map((link, index) => (
              <>
                <a
                  key={index}
                  href={link.href}
                  className={`text-gray-900 hover:text-gray-900 ${link.title === "See It Live" ? "fancy1 relative" : ""
                    } ${link.title === "Schedule Meeting" ? "fancy1" : ""}`}
                >
                  {link.title}
                </a>
              </>
            ))}
            <img src={boyEmoji} alt="" className=" absolute w-16 right-[40%] sm:right-[45%] md:right-[30%] top-[3.2rem] md:top-[2.7rem] " /> */}
            <div className="relative sm:text-left">
              <button
                ref={buttonRef}
                onClick={() =>
                (window.location.href =
                  "https://real-estate-landing-three.vercel.app/")
                }
                className="fancy1 text-gray-900 hover:text-gray-900 w-full py-2 lg:py-2 px-8 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1.1rem] lg:text-lg overflow-hidden
            relative"
              >
                {strings.tryDemoButton}
              </button>
              <img src={boyEmoji} alt="" className="w-16 absolute -top-5 right-[45%] md:right-20 lg:right-28 xl:right-40 2xl:right-48" />
            </div>
            <button
              ref={buttonRef}
              onClick={() =>
              (window.location.href =
                "https://real-estate-landing-three.vercel.app/")
              }
              className="fancy1 text-gray-900 hover:text-gray-900 py-2 lg:py-2 px-8 mb-1 lg:mb-0 rounded-[8px]
            font-semibold font-sans text-[1.1rem] lg:text-lg overflow-hidden
            relative"
            >
              <a
                href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07"
              >
                Schedule Meeting
              </a>
            </button>
          </div>


        </div>
        <div className="mt-10 border-t pt-6 text-gray-500 text-center lg:text-left flex flex-col lg:flex-row justify-between items-center">
          <div className="flex gap-4 mb-4 md:mb-0">
            <Link to="https://www.linkedin.com/company/360-aqar/">
              <FaLinkedin size={25} /> </Link>
            <Link to="https://www.instagram.com/360aqarsaudi?igsh=cHc1eTRhbzlrbnVr"><AiFillInstagram size={25} /> </Link>
            <Link to="https://x.com/3Aqar7267"><FaSquareXTwitter size={25} /></Link>
          </div>
          <p>© Copyright 2024 - 360 Aqar, All Rights Reserved.</p>

        </div>
      </div>
    </footer >
  );
};

export default Footer;
