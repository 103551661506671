import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Features from "./pages/Features";
import Footer from "./pages/Footer";
import Hero from "./pages/Hero";
import HowItWork from "./pages/HowItWork";
import Partners from "./pages/Partners";
import Stats from "./pages/Stats";
import Subscription from "./pages/Subscription";
import TryDemo from "./pages/TryDemo";
import FAQSection from "./pages/FAQSection";
import MainFeatures from "./pages/MainFeatures";
import Navbar from "./components/Navbar";
import whatsappAvatar from "./assets/admin.png";
import About from "./pages/About";
import { trackPageView } from "./analytics";
import ChallengeVsSolutions from "./pages/ChallengeVsSolutions";

const msg =
  "Welcome to 360aqar,\nYour premier destination for real estate solutions!";
// ''
const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

function App() {
  return (
    <>
      <Helmet>
        <title>Virtual Realty Home - Immersive 3D Property Tours</title>
        <meta
          name="description"
          content="Explore our Virtual Realty Home for immersive 3D property tours, VR walk-throughs, and metaverse experiences. Perfect for real estate agencies looking to lift expectations and showcase properties globally."
        />
      </Helmet>
      <TrackPageView />
      <Navbar />
      <Hero />
      <Features />
      <ChallengeVsSolutions />
      {/* <HowItWork /> */}
      <Subscription />
      <MainFeatures />
      <TryDemo />
      {/* <AboutUs /> */}
      <About />
      <Stats />
      <Partners />
      <FAQSection />
      <ContactUs />
      <Footer />
      {/* <FloatingWhatsApp
        phoneNumber="966564733866"
        accountName="360Aqar"
        avatar={whatsappAvatar}
        statusMessage="Online"
        messageDelay={1.63}
        chatMessage={msg}
        allowClickAway
        allowEsc
      /> */}
    </>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
