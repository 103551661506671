import React from 'react';
import strings from '../strings';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import logo from './../assets/Images/Logos/Pngs/Final-01.png';
import arrowLeft from './../assets/left4arrows.png'
import arrowRight from './../assets/right4arrows.png'
import roundWithArrow from './../assets/roundWithArrows.png';

const ChallengeVsSolutions = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Ensure the animation only runs once
        threshold: 0.1, // Trigger the animation when 10% of the component is visible
    });

    const menuVars = {
        initial: {
            opacity: 0,
            y: 80,
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: 'easeOut',
            },
        },
    };

    return (
        <motion.div
            ref={ref}
            variants={menuVars}
            initial="initial"
            animate={inView ? 'animate' : 'initial'}
        >
            <section
                className="mt-8 py-8 pb-16  mx-4 sm:mx-6"
            >
                <div className="max-w-[100%] lg:max-h-[95%] xl:max-w-[95%] mx-auto text-center mb-8 lg:mb-16  ">
                    <h2 className="text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-bold py-8 font-serif">
                        {strings.problem}
                        <span className="text-[#1189CC]">{strings.vs} </span>
                        {strings.solution}
                    </h2>
                    <div className='text-black/50 px-4 text-sm md:px-8 md:text-base md:leading-6 lg:text-lg lg:leading-7 pb-4 lg:px-20 xl:px-24  '>
                        {strings.problemVsSolutionDescription}
                    </div>
                    <div className="relative flex flex-col md:flex-row items-center justify-between md:h-[22rem] lg:h-[25rem] xl:h-[32rem] 2xl:h-[38rem]  mt-16">
                        <div className="w-full md:w-[36%] lg:w-[37%] flex flex-col gap-4  h-full justify-between">
                            {strings.challengeArrayLeft.map((problem) => (
                                <div key={problem.id} className="flex flex-col-reverse sm:flex-row items-center md:gap-4 justify-between bg-[#DCEBF3] rounded-2xl lg:rounded-full p-2 lg:p-4 xl:p-6">
                                    <div className="text-left space-y-1 xl:space-y-2 sm:pl-2 w-full">
                                        <h3 className="text-sm lg:text-[1.1rem] xl:text-lg 2xl:text-xl font-semibold">{problem.titleBold}</h3>
                                        <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg text-black/50">{problem.disc}</p>
                                    </div>
                                    <div className="flex items-center justify-center px-4 lg:px-6 py-2 lg:py-4 rounded-full bg-white text-black font-bold">
                                        {problem.id}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-[28%] lg:w-[26%] h-[75%] lg:h-[85%] 2xl:h-[90%] hidden md:flex ">
                            <div className='w-full h-auto flex items-center justify-center'>
                                <img
                                    src={roundWithArrow} // Replace with your actual logo path
                                    alt="360 Aqar Logo"
                                    className="h-full w-full"
                                    gap-4 />
                            </div>
                        </div>
                        <div className='md:hidden flex items-center justify-center my-4 sm:my-6 md:my-10'>
                            <div className='h-auto w-[50%] md:w-[30%] rounded-full p-4 bg-[#DCEBF3] '>
                                <img src={logo} alt="" className='h-full w-full size-40' />
                            </div>
                        </div>
                        {/* <div className='w-[30%] '></div> */}
                        {/* <div className='hidden lg:flex gap-24 -z-10 absolute left-1/2 transform -translate-x-1/2'>
                            <div className=''>
                                <img src={arrowLeft} alt="" />
                            </div>
                            <div className=" absolute top-[16%] left-1/2 transform -translate-x-1/2">
                                <div className='p-4 w-full h-auto flex items-center justify-center rounded-full bg-[#DCEBF3] '>
                                    <img
                                        src={logo} // Replace with your actual logo path
                                        alt="360 Aqar Logo"
                                        className="size-40 2xl:size-48 "
                                        gap-4 />
                                </div>
                            </div>
                            <div className=''>
                                <img src={arrowRight} alt="" />
                            </div>
                        </div> */}
                        <div className="w-full md:w-[36%] lg:w-[37%] flex flex-col gap-4  h-full justify-between">
                            {strings.challengeArrayRight.map((problem) => (
                                <div key={problem.id} className="flex flex-col sm:flex-row items-center bg-gradient-to-br from-[#1189CC] to-[#084466] rounded-2xl lg:rounded-full p-2 lg:p-4 xl:p-6">
                                    <div className="flex  items-center justify-center px-4 lg:px-6 py-2 lg:py-4 rounded-full bg-white text-[#084466] font-bold">
                                        {problem.id}
                                    </div>
                                    <div className="text-left space-y-1 xl:space-y-2 w-full sm:ml-1 lg:ml-2">
                                        <h3 className="text-sm lg:text-[1.1rem] xl:text-lg 2xl:text-xl text-white font-semibold">{problem.titleBold}</h3>
                                        <p className="text-xs lg:text-sm xl:text-base 2xl:text-lg text-white/50">{problem.disc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
}

export default ChallengeVsSolutions;