import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import vrImage from "./../assets/MagicToolsVRand360.webp";
import smartWeb from './../assets/MagicTools_SmartWebApp.jpg'
import interactive from './../assets/MagicTools_InteractiveKiosk.png'
import strings from "../strings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Register GSAP plugin
gsap.registerPlugin(ScrollTrigger);

const MainFeatures = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const featureRefs = useRef([]);
  const dotsRefs = useRef([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentSlide(next),
    appendDots: (dots) => (
      <div>
        <ul
          style={{
            margin: "0px",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        ref={(el) => (dotsRefs.current[i] = el)}
        style={{
          width: i === currentSlide ? "25px" : "10px",
          height: i === currentSlide ? "8px" : "10px",
          borderRadius: i === currentSlide ? "15px" : "50%",
          background: i === currentSlide ? "#084466" : "rgba(8, 68, 102, 0.15)",
          display: "inline-block",
        }}
      ></div>
    ),
  };

  useEffect(() => {
    featureRefs.current.forEach((feature, index) => {
      if (index === currentSlide) {
        gsap.fromTo(
          feature,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 0, duration: 1, ease: "circ.in" }
        );
      }
    });

    dotsRefs.current.forEach((dot, index) => {
      if (index === currentSlide) {
        gsap.to(dot, {
          scale: 1.2,
          backgroundColor: "#084466",
          duration: 0.5,
          stagger: true,
          ease: "back.in",
        });
      } else {
        gsap.to(dot, {
          scale: 1,
          backgroundColor: "rgba(8, 68, 102, 0.15)",
          duration: 0.5,
          ease: "back.out",
        });
      }
    });
  }, [currentSlide]);

  useEffect(() => {
    featureRefs.current.forEach((feature) => {
      gsap.fromTo(
        feature,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power4.out",
          scrollTrigger: {
            trigger: feature,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  useEffect(() => {
    gsap.to(".btn", {
      scale: 1.1,
      duration: 0.2,
      ease: "sine",
      paused: true,
      reversed: true,
      onStart: () => {
        gsap.to(".btn", { boxShadow: "0 0 10px rgba(8, 68, 102, 0.6)" });
      },
      onReverseComplete: () => {
        gsap.to(".btn", { boxShadow: "none" });
      },
    });

    const button = document.querySelector(".btn");
    button.addEventListener("mouseenter", () => gsap.to(button).play());
    button.addEventListener("mouseleave", () => gsap.to(button).reverse());
  }, []);

  const renderDescription = (descriptions) => {
    return descriptions.map((desc, i) => {
      const isBold = i % 2 === 0;
      const isIndented = i % 2 !== 0;

      return (
        <li
          key={i}
          className={` text-[0.7rem] md:text-[1.1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] text-pretty z-50 ${isBold ? "font-bold" : "font-normal"
            } ${isIndented ? "pl-6 list-none" : "list-decimal"}`}
        >
          {desc}
        </li>
        // <li
        //   key={i}
        //   className={`text-[0.7rem] md:text-[1.1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] text-pretty z-50 ${
        //     isBold ? "font-bold" : "font-normal"
        //   } ${isIndented ? "pl-6 list-none" : "list-decimal"}`}
        // >
        //   {desc}
        // </li>
      );
    });
  };

  return (
    <section className="  mainFeature relative flex flex-col items-center mt-12 px-4 lg:px-10 my-20">
      <h2 className="text-center text-[1.8rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem] text-black uppercase sm:mb-0 mb-10 font-serif font-bold">
        {/* Section Title */}
        {strings.mainFeaturesTitle1}{" "}
        <span className="text-[#1189CC]">{strings.mainFeaturesTitle2} </span>
      </h2>

      <Slider
        {...settings}
        className="max-w-5xl lg:max-w-[75rem]  xl:max-w-[85rem] 2xl:max-w-[100rem] md:mx-auto w-full py-5 flex justify-center "
      >
        {[
          strings.mainFeaturesSubTitle1,
          strings.mainFeaturesSubTitle2,
          strings.mainFeaturesSubTitle3,
        ].map((title, index) => (
          <div key={index} className="h-full flex justify-center items-center ">
            <div
              ref={(el) => (featureRefs.current[index] = el)}
              id="main"
              className="relative flex flex-col-reverse md:h-[600px] lg:h-[500px] w-[85%] sm:w-[70%] md:w-[90%] mx-auto bg-[#DCEBF3] rounded-[25px] overflow-hidden  lg:mx-20 "
            >
              {/* Decorative shapes */}

              <div className="absolute inset-0 ">
                {/* Circle */}
                <div className="absolute -top-24 -left-24 md:-top-36 md:-left-36 w-40 h-40 md:w-60 md:h-60 bg-[#084466]/10 rounded-full"></div>
                {/* Rectangle */}
                <div className="absolute sm:top-0 sm:right-12 sm:h-16 sm:z-50 md:-top-14 right-0 bottom-2/3 lg:-top-16 lg:left-2/3 w-32 h-20 md:w-40 md:h-32 lg:w-60 lg:h-40 bg-[#ECF4F9]/25 md:bg-[#ECF4F9]/50 xl:bg-[#ECF4F9]/50 z-30"></div>
              </div>
              <div className="flex flex-col-reverse h-full md:flex-row">
                <div className="px-8 flex flex-col justify-between  py-4 lg:py-8 h-full sm:text-left w-full md:w-[50%]  ">
                  <div className="relative  sm:mt-2 my-4 ">
                    {/* Line */}
                    <div className="absolute -top-1 left-0 w-16 h-[0.15rem]  bg-[#1189CC] rounded-[20px]"></div>
                    {/* Title */}
                    <h3 className="font-semibold lg:font-medium font-serif text-black text-[1.05rem] md:text-[1.7rem] lg:text-[1.4rem] xl:text-[1.7rem]  2xl:text-[2rem] mb-2">
                      {title}
                    </h3>
                  </div>
                  <div className="">
                    <ol className="list-decimal list-inside font-light font-sans text-black/50 space-y-1 xl:space-y-2  2xl:space-y-5 mb-4 md:mb-5 lg:mb-3 z-50">
                      {/* Description */}
                      {index === 0
                        ? renderDescription(strings.mainFeaturesDescription1)
                        : index === 1
                          ? renderDescription(strings.mainFeaturesDescription2)
                          : renderDescription(strings.mainFeaturesDescription3)}
                    </ol>
                  </div>

                  {/* Button Text */}
                  <button
                    className="btn bg-[#084466] text-white py-2 px-8 rounded-full shadow-md font-sans 
                   font-bold hover:bg-[#072e54] transition duration-300 text-[1rem] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] "
                  >
                    {strings.interactiveVrTool}
                  </button>
                </div>
                <div className="flex justify-center z-50 h-[200px] md:h-full w-full md:w-[50%]">
                  <img
                    src={index === 0
                      ? smartWeb
                      : index === 1
                        ? vrImage
                        : interactive}
                    alt="Interactive VR Tour"
                    className=" rounded-md w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default MainFeatures;
