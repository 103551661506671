import React, { forwardRef, useState } from "react";
import { useParams } from "react-router-dom";
import Aqar from "./../assets/SVGs/Aqar";

const AqarHome = forwardRef((props, ref) => {
  const { id } = useParams();
  const _id = parseInt(id, 10);

  const [hoveredId, setHoveredId] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (id, event) => {
    if (event) {
      setHoveredId(id);
      setModalPosition({ top: event.clientY + 10, left: event.clientX + 10 });
    }
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const handleMouseMove = (event) => {
    if (hoveredId && event) {
      setModalPosition({ top: event.clientY + 10, left: event.clientX + 10 });
    }
  };

  return (
    <div ref={ref} onMouseMove={handleMouseMove}>
      <div>
        <Aqar
          hoveredId={hoveredId}
          handleMouseEnter={(id, event) => handleMouseEnter(id, event)}
          handleMouseLeave={handleMouseLeave}
        />
      </div>
      {hoveredId && (
        <div
          className="absolute pointer-events-none p-4 bg-black  bg-opacity-70 rounded-lg text-white transition-opacity duration-300 font-thin"
          style={{ top: modalPosition.top, left: modalPosition.left }}
        >
          <p className="text-xs lg:text-sm  leading-6">Hover over a house</p>
          <p className="text-xs lg:text-sm  leading-6">to see the details</p>
        </div>
      )}
    </div>
  );
});

export default AqarHome;
