import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
// Components

// Assets
import AddImage1 from "../assets/Images/about1.png";
import AddImage2 from "../assets/Images/about2.png";
import AddImage3 from "../assets/Images/about3.png";
import AddImage4 from "../assets/Images/about4.png";
import { Link } from "react-scroll";
import about from '../assets/AboutUs.jpg'
import strings from "../strings";

export default function About() {
  return (
    <section id="about" className="relative w-full  mx-auto p-4 mt-10 md:mt-12 lg:mt-16">
      <Wrapper id="about">
        <div className="xl:mx-40 " style={{ padding: " 0" }}>
          <div className=" bg-white  flex justify-center">
            <div className="container  rounded-[1.2rem]  lg:rounded-3xl overflow-hidden shadow-lg ring-1 ring-[#1189CC]">
              <div className="flexSpaceCenter flex justify-between">
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="w-full md:w-[50%] p-4 xl:p-12 lg:p-6  xl:flex flex-col justify-center">
                    <div className="relative xl:mb-4">
                      <h2 className="text-2xl font-bold relative z-20 text-left">
                        {strings.aboutUsTitle}
                      </h2>
                      <div className="absolute bottom-1  w-16 h-[0.15rem] bg-[#1189CC] rounded-[20px]"></div>
                    </div>
                    <div className="2xl:flex 2xl:flex-col 2xl:justify-between 2xl:h-full 2xl:py-4">
                      <p className="font-sans  text-justify text-[0.75rem] md:text-[0.8rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                        {strings.aboutUsDescription}
                      </p>
                      <br />
                      <p className="font-sans  text-justify text-[0.75rem] md:text-[0.8rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                        {strings.aboutUsDescription2}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-[50%] flex items-center overflow-hidden">
                    <img src={about} alt="" className="w-full h-full" />
                  </div>
                </div>
                {/* <AddLeft>
                  <motion.h2
                    className="font40 extraBold"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.3 }}
                    viewport={{ once: true, amount: 0.3 }}
                  >
                    <div className="mb-1 w-16 h-[0.15rem] bg-[#1189CC] rounded-[20px]"></div>
                    <h2 className="text-2xl font-bold mb-4 relative z-20 text-left">
                      {strings.aboutUsTitle}
                    </h2>
                  </motion.h2>
                  <motion.p
                    className="font12"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    viewport={{ once: true, amount: 0.4 }}
                  >
                    <p className="font-sans  text-justify text-[0.75rem] md:text-[0.8rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                      {strings.aboutUsDescription}
                    </p>
                    <br></br>
                    <p className="font-sans  text-justify text-[0.75rem] md:text-[0.8rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
                      {strings.aboutUsDescription2}
                    </p>
                  </motion.p>

                  <ButtonsRow
                    className="flexNullCenter"
                    style={{ margin: "30px 0" }}
                  >
                    <motion.div
                      whileInView={{ opacity: 1, translateY: 0 }}
                      initial={{ opacity: 0, translateY: 20 }}
                      transition={{ duration: 0.65, delay: 0.4 }}
                      viewport={{ once: true, amount: 0.5 }}
                      style={{ width: "190px" }}
                    >
                      {/* <Link to="contact">
                      <FullButton title="Get Started" />
                    </Link> */}
                {/* </motion.div>
              <motion.div
                whileInView={{ opacity: 1, translateY: 0 }}
                initial={{ opacity: 0, translateY: 20 }}
                transition={{ duration: 0.65, delay: 0.45 }}
                viewport={{ once: true, amount: 0.5 }}
                style={{ width: "190px", marginLeft: "15px" }}
              > */}
                {/* <FullButton
                      title="Whatsapp"
                      action={() => window.open("https://wa.me/918000797987")}
                      border
                    /> */}
                {/* </motion.div>
            </ButtonsRow>
          </AddLeft> */}
                {/* <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1
                        whileInView={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.4 }}
                        transition={{ duration: 0.63, delay: 0.2 }}
                        viewport={{ once: true }}
                        className="flexCenter "
                      >
                        <img src={AddImage1} alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2
                        whileInView={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.4 }}
                        transition={{ duration: 0.78, delay: 0.24 }}
                        viewport={{ once: true }}
                        className="flexCenter"
                      >
                        <img src={AddImage2} alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3
                        whileInView={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.4 }}
                        transition={{ duration: 0.55, delay: 0.2 }}
                        viewport={{ once: true }}
                        className="flexCenter"
                      >
                        <img src={AddImage3} alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4
                        whileInView={{ opacity: 1, scale: 1 }}
                        initial={{ opacity: 0, scale: 0.4 }}
                        transition={{ duration: 0.63, delay: 0.3 }}
                        viewport={{ once: true }}
                        className="flexCenter"
                      >
                        <img src={AddImage4} alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight> */}
              </div>
            </div>
          </div>
        </div >
      </Wrapper >
    </section >
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Advertising = styled.div`
  margin: 30px 0;
  padding: 10px 10px;
  position: relative;
  @media (max-width: 1160px) {
    padding: 40px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 40px 0;
    margin: 40px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 45%;

  p {
    max-width: 100%;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 1;
     top: -40px;
    text-align: center;
    h2 {
      line-height: 1.5rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 55%;
  position: absolute;
  top: 10%;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 2;
   top: -20px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled(motion.div)`
  width: 65%;
  margin: 0 6% 10px 6%;
   @media (max-width: 860px) {
   width: 55%;
  margin: 0 5% 10px 2%;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled(motion.div)`
  width: 40%;
  margin: 0 1% 2px 0%;
     @media (max-width: 860px) {
     width: 40%;
  margin:0% 1% 2px 0%;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled(motion.div)`
  width: 40%;
  margin-left: 40%;
   @media (max-width: 860px) {
  width: 40%;
  margin-left: 12%;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled(motion.div)`
  width: 60%;
  margin: 0 5% auto;
   @media (max-width: 860px) {
    width: 60%;
  margin: 0 5% auto;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
